import React, { useState } from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../components/section-title";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const MapLocationListTwoSection = ({ data }) => {
    const [activeId, setActiveId] = useState();

    return (
        <section className="map-location-list-section  relative ">
            <span className="map-location-image-top -mb-1 block w-full h-auto">
                <GatsbyImage
                    className="w-full"
                    image={getImage(data?.images[1].src)}
                    alt={data?.images[1].alt}
                />
            </span>
            <div className="section-space-pt bg-[#221F1E]">
                <div className="container-full">
                    {data?.section_title && (
                        <SectionTitle
                            heading={data?.section_title?.heading}
                            {...data.section_title}
                        />
                    )}
                    <ul className="map-location-list grid items-center gap-x-8 grid-cols-1 md:grid-cols-2">
                        {data?.items?.map((item) => (
                            <li
                                key={item?.id}
                                className={`map-location-item-two ${
                                    activeId === item.id ? "active" : ""
                                } relative flex mb-11 last:mb-0 cursor-pointer bg-transparent items-center gap-x-6 lg:gap-x-10`}
                                onClick={() => setActiveId(item.id)}
                                onKeyPress={() => {}}
                                role="presentation"
                            >
                                <div className="icon relative flex justify-center text-center items-center shrink-0 text-3xl w-[70px] h-[70px] text-primary bg-[#272423] rounded-full">
                                    <span className="icon-body">
                                        <i
                                            className={`${item.icon} leading-none flex`}
                                        ></i>
                                    </span>
                                </div>
                                <div className="content">
                                    <h3 className="title text-white">
                                        {item.title}
                                    </h3>
                                    <p className="address text-white">
                                        {item.description}
                                    </p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="map-location-layer relative section-space-pt">
                    <GatsbyImage
                        className="w-full cover object-cover"
                        image={getImage(data?.images[0].src)}
                        alt={data?.images[0].alt}
                    />
                    {data?.items?.map((item) => (
                        <div
                            key={item.id}
                            className={`absolute group w-3 md:w-10 h-3 md:h-10 cursor-pointer rounded-full bg-primary shadow-[0_0_0_10px_rgb(14_164_120_/_10%)] ${
                                activeId === item.id ? "active" : ""
                            } ${item.id}`}
                            onClick={() => setActiveId(item.id)}
                            onKeyPress={() => {}}
                            role="presentation"
                        >
                            <span
                                className="title opacity-0 hover:opacity-100 before:absolute before:bottom-[-8px]
                                        before:w-4 before:h-4 before:content-[''] before:-z-[1] before:-translate-x-1/2 before:left-1/2
                                        before:rotate-45 before:bg-white shadow-[0_10px_40px_0_rgb(0_0_0_/_15%)] text-black rounded
                                         bg-white p-[5px_15px] -translate-x-1/2 whitespace-nowrap text-sm font-semibold left-1/2 top-[-70px] absolute "
                            >
                                {item.title}
                            </span>
                        </div>
                    ))}
                    <div className="text-center absolute bottom-10 text-white w-full">
                        <a
                            className="google-map-link"
                            href={data?.link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fi fi-map-1 mr-2"></i>打開地圖
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

MapLocationListTwoSection.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                level: PropTypes.string,
                content: PropTypes.string,
            })
        ),
        link: PropTypes.string,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]),
                alt: PropTypes.string,
            })
        ),
    }),
};

export default MapLocationListTwoSection;
